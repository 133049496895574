import { pipe, tap } from "wonka";
import { Exchange, Operation } from "urql";
import { print } from "graphql";

interface QueryTracker {
  timestamps: number[];
  queryString: string;
  variables: Operation["variables"];
}

// Configuration
const TIME_WINDOW = 45000;
const FREQUENCY_THRESHOLD = 12;
const MAX_TRACKED_INSTANCES = 20;

// Sometimes we might have infinite query loops in our app
// which might be overlooked by React. This exchange is to identify those
// cases where a certain query is triggered every n seconds or so for whatever
// reason, so that we can identify the root cause
export const infiniteLoopExchange: Exchange =
  ({ forward }) =>
  (ops$) => {
    const queryLog = new Map<string, QueryTracker>();

    return pipe(
      ops$,
      tap((operation: Operation) => {
        if (operation.kind === "query") {
          const now = Date.now();
          const queryString = print(operation.query);
          const queryHash = `${queryString}-${JSON.stringify(
            operation.variables
          )}`;

          const tracker = queryLog.get(queryHash) || {
            timestamps: [],
            queryString,
            variables: operation.variables,
          };

          tracker.timestamps.push(now);
          if (tracker.timestamps.length > MAX_TRACKED_INSTANCES) {
            tracker.timestamps = tracker.timestamps.slice(
              -MAX_TRACKED_INSTANCES
            );
          }

          const cutoffTime = now - TIME_WINDOW;
          tracker.timestamps = tracker.timestamps.filter(
            (time) => time > cutoffTime
          );

          if (tracker.timestamps.length >= FREQUENCY_THRESHOLD) {
            const timeSpan = now - tracker.timestamps[0];
            console.error(
              `Potential infinite loop detected!\n` +
                `Query executed ${tracker.timestamps.length} times in ${
                  timeSpan / 1000
                } seconds\n` +
                `Query: ${queryString}\n` +
                `Variables: ${JSON.stringify(operation.variables)}\n`
            );

            queryLog.delete(queryHash);
            return;
          }

          queryLog.set(queryHash, tracker);
        }
      }),
      forward
    );
  };
